import { Box, Text, Button, ProgressBar, Spinner } from '@primer/react';
import { DashboardLayout } from '../components/DashboardLayout';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { collection, query, where, getDocs, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { 
  KeyIcon, 
  FileIcon, 
  CreditCardIcon,
  ArrowRightIcon
} from '@primer/octicons-react';
import { LogoTextShape } from '../assets';
import socialImage from '../assets/images/social.jpg';
import { useWallet } from '../hooks/useWallet';

interface DashboardStats {
  apiKeysCount: number;
  templatesCount: number;
  walletBalance: number;
  freeUsage: {
    used: number;
    total: number;
  };
  tokens: {
    used: number;
    available: number;
  };
}

export default function Dashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { walletData, loading: walletLoading } = useWallet();
  const [stats, setStats] = useState<DashboardStats>({
    apiKeysCount: 0,
    templatesCount: 0,
    walletBalance: 0,
    freeUsage: {
      used: walletData?.free_usage || 0,
      total: walletData?.free_usage_limit || 1000
    },
    tokens: {
      used: walletData?.token_usage || 0,
      available: walletData?.available_tokens || 0
    }
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadStats = async () => {
      if (!user) return;

      try {
        const userRef = doc(db, 'users', user.uid);

        // Get API Keys count
        const apiKeysQuery = query(
          collection(db, 'api_keys'),
          where('user_ref', '==', userRef)
        );
        const apiKeysSnapshot = await getDocs(apiKeysQuery);

        // Get Templates count
        const templatesQuery = query(
          collection(db, 'templates'),
          where('user_ref', '==', userRef)
        );
        const templatesSnapshot = await getDocs(templatesQuery);

        setStats(prev => ({
          ...prev,
          apiKeysCount: apiKeysSnapshot.size,
          templatesCount: templatesSnapshot.size
        }));
      } catch (error) {
        console.error('Error loading dashboard stats:', error);
      } finally {
        setLoading(false);
      }
    };

    loadStats();
  }, [user]);

  useEffect(() => {
    if (walletData) {
      setStats(prev => ({
        ...prev,
        walletBalance: walletData.amount,
        freeUsage: {
          used: walletData.free_usage,
          total: walletData.free_usage_limit
        },
        tokens: {
          used: walletData.token_usage,
          available: walletData.available_tokens
        }
      }));
    }
  }, [walletData]);

  const calculatePercentage = (used: number, total: number) => {
    return (used / total) * 100;
  };

  return (
    <DashboardLayout>
      <Box sx={{ p: 4 }}>
        {/* Updated Welcome Section */}
        <Box sx={{ 
          bg: 'canvas.default',
          border: '1px solid',
          borderColor: 'border.default',
          borderRadius: 2,
          overflow: 'hidden',
          mb: 4,
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${socialImage})`,
            backgroundSize: 'contain',
            backgroundPosition: 'right center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.5,
            zIndex: 0
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 40%, transparent 70%)',
            zIndex: 1
          },
          zIndex: 0
        }}>
          <Box sx={{ 
            px: 4,
            py: 5,
            display: 'flex',
            alignItems: 'center',
            minHeight: '320px',
            position: 'relative',
            zIndex: 2,
            maxWidth: '1400px',
            mx: 'auto'
          }}>
            {/* Text Content - Constrained to left half */}
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              width: '50%',  // Added to constrain width
              pr: 4  // Added padding to keep text away from gradient
            }}>
              <Text sx={{ 
                fontSize: 1,
                color: 'fg.muted'
              }}>
                Welcome back
              </Text>
              <Text as="h1" sx={{ 
                fontSize: 6,
                fontWeight: 'bold',
                color: 'fg.default',
                lineHeight: '1.25',
                mb: 2
              }}>
                {user?.displayName}
              </Text>
              <Text sx={{ 
                fontSize: 2,
                color: 'fg.muted',
                lineHeight: 1.5
              }}>
                Here's an overview of your account and recent activity. Manage your API keys,
                templates, and monitor your usage all in one place.
              </Text>
            </Box>
          </Box>
        </Box>

        {/* Stats Grid */}
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: 4,
          mb: 4
        }}>
          {/* API Keys Card */}
          <Box sx={{ 
            p: 4,
            border: '1px solid',
            borderColor: 'border.default',
            borderRadius: 2,
            bg: 'canvas.subtle',
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <KeyIcon size={16} />
                <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>API Keys</Text>
              </Box>
              <Text sx={{ fontSize: 4, fontWeight: 'bold' }}>
                {stats.apiKeysCount}
              </Text>
            </Box>
            <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                onClick={() => navigate('/dashboard/api-keys')}
                variant="default"
                trailingVisual={ArrowRightIcon}
              >
                Manage API Keys
              </Button>
            </Box>
          </Box>

          {/* Templates Card */}
          <Box sx={{ 
            p: 4,
            border: '1px solid',
            borderColor: 'border.default',
            borderRadius: 2,
            bg: 'canvas.subtle',
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <FileIcon size={16} />
                <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>Templates</Text>
              </Box>
              <Text sx={{ fontSize: 4, fontWeight: 'bold' }}>
                {stats.templatesCount}
              </Text>
            </Box>
            <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                onClick={() => navigate('/dashboard/templates')}
                variant="default"
                trailingVisual={ArrowRightIcon}
              >
                Manage Templates
              </Button>
            </Box>
          </Box>

          {/* Wallet & Usage Card */}
          <Box sx={{ 
            p: 4,
            border: '1px solid',
            borderColor: 'border.default',
            borderRadius: 2,
            bg: 'canvas.subtle',
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <CreditCardIcon size={16} />
                <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>Usage & Billing</Text>
              </Box>

              {/* Free Usage */}
              <Box sx={{ mb: 3 }}>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  mb: 1
                }}>
                  <Text sx={{ fontSize: 1 }}>Free Usage</Text>
                  <Text sx={{ fontSize: 1 }}>
                    {stats.freeUsage.total - stats.freeUsage.used} of {stats.freeUsage.total}
                  </Text>
                </Box>
                <ProgressBar 
                  progress={calculatePercentage(
                    stats.freeUsage.total - stats.freeUsage.used,
                    stats.freeUsage.total
                  )}
                  sx={{
                    bg: 'border.default',
                    '> div': {
                      bg: 'success.emphasis'
                    }
                  }}
                />
                <Text sx={{ 
                  fontSize: 1, 
                  color: 'fg.muted',
                  mt: 1
                }}>
                  {stats.freeUsage.used} usages consumed
                </Text>
              </Box>

              {/* Tokens */}
              <Box sx={{ mb: 3 }}>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  mb: 1
                }}>
                  <Text sx={{ fontSize: 1 }}>Tokens</Text>
                  <Text sx={{ fontSize: 1 }}>
                    {stats.tokens.available} of {stats.tokens.used + stats.tokens.available}
                  </Text>
                </Box>
                <ProgressBar 
                  progress={calculatePercentage(
                    stats.tokens.available,
                    stats.tokens.used + stats.tokens.available
                  )}
                  sx={{
                    bg: 'border.default',
                    '> div': {
                      bg: 'accent.emphasis'
                    }
                  }}
                />
                <Text sx={{ 
                  fontSize: 1, 
                  color: 'fg.muted',
                  mt: 1
                }}>
                  {stats.tokens.used} tokens consumed
                </Text>
              </Box>
            </Box>
            <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                onClick={() => navigate('/dashboard/usage')}
                variant="default"
                trailingVisual={ArrowRightIcon}
              >
                View Details
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
}