import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';

export interface WalletData {
  free_usage: number;
  free_usage_limit: number;
  token_usage: number;
  available_tokens: number;
  amount: number;
  updated_at: Date;
}

export const useWallet = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [walletData, setWalletData] = useState<WalletData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWalletData = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        const walletRef = doc(db, 'wallet', user.uid);
        const walletDoc = await getDoc(walletRef);

        if (walletDoc.exists()) {
          const data = walletDoc.data();
          setWalletData({
            free_usage: data.free_usage,
            free_usage_limit: data.free_usage_limit,
            token_usage: data.token_usage,
            available_tokens: data.available_tokens,
            amount: data.amount,
            updated_at: data.updated_at?.toDate()
          });
        }
      } catch (err) {
        console.error('Error fetching wallet data:', err);
        setError('Failed to load wallet data');
      } finally {
        setLoading(false);
      }
    };

    fetchWalletData();
  }, [user]);

  return { walletData, loading, error };
}; 