import { Box, Text, FormControl, TextInput, Button, Flash } from '@primer/react';
import { DashboardLayout } from '../components/DashboardLayout';
import { useAuth } from '../contexts/AuthContext';
import { APP_VERSION } from '../config/version';
import { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

export default function Settings() {
  const { user } = useAuth();
  const [saved, setSaved] = useState(false);
  const [displayName, setDisplayName] = useState(user?.displayName || '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async () => {
    if (!user) return;
    
    setIsSubmitting(true);
    try {
      // Update the user document in Firestore
      await updateDoc(doc(db, 'users', user.uid), {
        display_name: displayName
      });
      
      setSaved(true);
      setTimeout(() => setSaved(false), 3000);
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <Box sx={{ p: 4 }}>
        <Text as="h2" sx={{ fontSize: 4, fontWeight: 'bold', mb: 4 }}>
          Profile Settings
        </Text>

        {saved && (
          <Flash variant="success" sx={{ mb: 3 }}>
            Profile settings saved successfully!
          </Flash>
        )}

        <Box sx={{ 
          maxWidth: '600px',
          border: '1px solid',
          borderColor: 'border.default',
          borderRadius: 2,
          p: 4
        }}>
          <FormControl sx={{ mb: 3 }}>
            <FormControl.Label>Display Name</FormControl.Label>
            <TextInput 
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              placeholder="Enter your display name"
            />
          </FormControl>

          <FormControl sx={{ mb: 4 }}>
            <FormControl.Label>Email</FormControl.Label>
            <TextInput 
              value={user?.email || ''} 
              placeholder="Enter your email"
              disabled
            />
            <FormControl.Caption>
              Email address cannot be changed
            </FormControl.Caption>
          </FormControl>

          <Button 
            onClick={handleSave}
            disabled={isSubmitting}
            variant="primary"
          >
            {isSubmitting ? 'Saving...' : 'Save Changes'}
          </Button>
        </Box>

        {/* Version Info */}
        <Box sx={{ mt: 6, color: 'fg.muted' }}>
          <Text sx={{ fontSize: 1 }}>
            Version: {APP_VERSION}
          </Text>
        </Box>
      </Box>
    </DashboardLayout>
  );
} 