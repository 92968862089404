import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@primer/react';
import { AuthProvider } from './contexts/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ApiKeys from './pages/ApiKeys';
import TemplateEditor from './pages/TemplateEditor';
import Templates from './pages/Templates';
import Settings from './pages/Settings';
import ApiDocumentation from './pages/ApiDocumentation';
import ApiUsage from './pages/ApiUsage';
import TemplatePrompt from './pages/TemplatePrompt';
import Usage from './pages/Usage';

function App() {
  return (
    <ThemeProvider>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/usage"
              element={
                <ProtectedRoute>
                  <Usage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/api-keys"
              element={
                <ProtectedRoute>
                  <ApiKeys />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/templates"
              element={
                <ProtectedRoute>
                  <Templates />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/templates/new"
              element={
                <ProtectedRoute>
                  <TemplateEditor />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/templates/edit/:id"
              element={
                <ProtectedRoute>
                  <TemplateEditor />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/api-documentation"
              element={
                <ProtectedRoute>
                  <ApiDocumentation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/api-usage/:apiKeyId"
              element={
                <ProtectedRoute>
                  <ApiUsage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/templates/prompt"
              element={
                <ProtectedRoute>
                  <TemplatePrompt />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<Login />} />
          </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;