import { useState } from 'react';
import { 
  Box, 
  Text, 
  Button, 
  ActionMenu, 
  ActionList,
  ProgressBar,
  Spinner
} from '@primer/react';
import { Dialog } from '@primer/react/experimental';
import { 
  HistoryIcon, 
  CreditCardIcon, 
  PlusIcon, 
  FiscalHostIcon
} from '@primer/octicons-react';
import { DashboardLayout } from '../components/DashboardLayout';
import { formatDistanceToNow } from 'date-fns';
import { useWallet } from '../hooks/useWallet';

interface Transaction {
  id: string;
  amount: number;
  type: 'credit_card' | 'cash' | 'usage';
  status: 'completed' | 'pending' | 'failed';
  created_at: Date;
}

export default function Usage() {
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { walletData, loading: walletLoading } = useWallet();

  const usageData = {
    freeLimit: walletData?.free_usage_limit || 1000,
    used: walletData?.free_usage || 0,
    tokens: {
      available: walletData?.available_tokens || 0,
      used: walletData?.token_usage || 0
    },
    wallet: {
      balance: walletData?.amount || 0,
      lastUpdated: walletData?.updated_at || new Date()
    }
  };

  const calculatePercentage = (used: number, total: number) => {
    if (total === 0) return 0;
    return (used / total) * 100;
  };

  return (
    <DashboardLayout>
      <Box sx={{ p: 4 }}>
        <Text as="h2" sx={{ fontSize: 4, fontWeight: 'bold', mb: 4 }}>
          Usage & Billing
        </Text>

        {walletLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <Spinner size="large" />
          </Box>
        ) : (
          <Box sx={{ 
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
            gap: 4,
            maxWidth: '1200px'
          }}>
            {/* Wallet Section */}
            <Box>
              <Box sx={{ 
                p: 4,
                border: '1px solid',
                borderColor: 'border.default',
                borderRadius: 2,
                bg: 'canvas.subtle'
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 2,
                  mb: 3
                }}>
                  <CreditCardIcon size={16} />
                  <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>Wallet</Text>
                </Box>
                
                <Box sx={{ mb: 4 }}>
                  <Text sx={{ 
                    fontSize: 4, 
                    fontWeight: 'bold',
                    display: 'block',
                    mb: 2
                  }}>
                    ${usageData.wallet.balance.toFixed(2)}
                  </Text>
                  <Box sx={{ 
                    pt: 2,
                    borderTop: '1px solid',
                    borderColor: 'border.muted'
                  }}>
                    <Text sx={{ 
                      fontSize: '12px',
                      color: 'fg.muted'
                    }}>
                      Last updated {formatDistanceToNow(usageData.wallet.lastUpdated)} ago
                    </Text>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                  <Button 
                    onClick={() => setIsHistoryModalOpen(true)}
                    variant="default"
                    leadingVisual={HistoryIcon}
                    sx={{ width: '100%' }}
                  >
                    Transaction History
                  </Button>

                  <ActionMenu>
                    <ActionMenu.Anchor>
                      <Button 
                        variant="primary"
                        leadingVisual={PlusIcon}
                        sx={{ width: '100%' }}
                      >
                        Top Up Wallet
                      </Button>
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.Item>
                          <ActionList.LeadingVisual>
                            <CreditCardIcon />
                          </ActionList.LeadingVisual>
                          Pay with Credit Card
                        </ActionList.Item>
                        <ActionList.Item>
                          <ActionList.LeadingVisual>
                            <FiscalHostIcon />
                          </ActionList.LeadingVisual>
                          Pay with Cash
                        </ActionList.Item>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </Box>
              </Box>
            </Box>

            {/* Usage Section */}
            <Box>
              {/* Free Usage Section */}
              <Box sx={{ 
                p: 4,
                border: '1px solid',
                borderColor: 'border.default',
                borderRadius: 2,
                mb: 4
              }}>
                <Text sx={{ fontSize: 2, fontWeight: 'bold', mb: 3 }}>Free Usage</Text>
                
                <Box sx={{ mb: 2 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    mb: 2
                  }}>
                    <Text>Remaining {usageData.freeLimit - usageData.used} of {usageData.freeLimit}</Text>
                    <Text>
                      {calculatePercentage(usageData.freeLimit - usageData.used, usageData.freeLimit).toFixed(1)}%
                    </Text>
                  </Box>
                  <ProgressBar 
                    progress={calculatePercentage(usageData.freeLimit - usageData.used, usageData.freeLimit)}
                    sx={{
                      bg: 'border.default',
                      '> div': {
                        bg: 'success.emphasis'
                      }
                    }}
                  />
                  <Text sx={{ 
                    fontSize: 1,
                    color: 'fg.muted',
                    mt: 2
                  }}>
                    {usageData.used} usages consumed
                  </Text>
                </Box>
              </Box>

              {/* Tokens Section */}
              <Box sx={{ 
                p: 4,
                border: '1px solid',
                borderColor: 'border.default',
                borderRadius: 2
              }}>
                <Text sx={{ fontSize: 2, fontWeight: 'bold', mb: 3 }}>Tokens</Text>
                
                <Box sx={{ mb: 2 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    mb: 2
                  }}>
                    <Text>
                      Remaining {usageData.tokens.available} of {usageData.tokens.used + usageData.tokens.available}
                    </Text>
                    <Text>
                      {calculatePercentage(
                        usageData.tokens.available,
                        usageData.tokens.used + usageData.tokens.available
                      ).toFixed(1)}%
                    </Text>
                  </Box>
                  <ProgressBar 
                    progress={calculatePercentage(
                      usageData.tokens.available,
                      usageData.tokens.used + usageData.tokens.available
                    )}
                    sx={{
                      bg: 'border.default',
                      '> div': {
                        bg: 'accent.emphasis'
                      }
                    }}
                  />
                  <Text sx={{ 
                    fontSize: 1,
                    color: 'fg.muted',
                    mt: 2
                  }}>
                    {usageData.tokens.used} tokens consumed
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {/* Transaction History Modal */}
        {isHistoryModalOpen && (
          <Dialog 
            title="Transaction History"
            onClose={() => setIsHistoryModalOpen(false)}
            width="xlarge"
            sx={{ 
              maxWidth: '50vw !important',
              margin: '0 auto'
            }}
          >
            <Box sx={{ p: 3 }}>
              {loading ? (
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center', 
                  justifyContent: 'center',
                  py: 6,
                  gap: 3
                }}>
                  <Spinner size="large" />
                  <Text sx={{ color: 'fg.muted' }}>Loading transactions...</Text>
                </Box>
              ) : (
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}>
                  {/* Empty State */}
                  <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 6,
                    gap: 2
                  }}>
                    <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>No Transactions Yet</Text>
                    <Text sx={{ color: 'fg.muted', textAlign: 'center' }}>
                      Your transaction history will appear here once you start using the service.
                    </Text>
                  </Box>

                  {/* Transaction List - Currently hidden, will be shown when we have data */}
                  <Box sx={{ display: 'none' }}>
                    {/* Transaction Item Template */}
                    <Box sx={{ 
                      p: 3,
                      borderBottom: '1px solid',
                      borderColor: 'border.default',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <Box>
                        <Text sx={{ fontWeight: 'bold' }}>Transaction Type</Text>
                        <Text sx={{ fontSize: 0, color: 'fg.muted' }}>Date</Text>
                      </Box>
                      <Box sx={{ textAlign: 'right' }}>
                        <Text sx={{ 
                          fontWeight: 'bold',
                          color: 'success.fg'  // or 'danger.fg' for negative amounts
                        }}>
                          Amount
                        </Text>
                        <Text sx={{ 
                          fontSize: 0,
                          color: 'fg.muted',
                          textTransform: 'capitalize'
                        }}>
                          Status
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Dialog>
        )}
      </Box>
    </DashboardLayout>
  );
} 