import { useState } from 'react';
import { 
  Box, 
  Select, 
  Textarea, 
  Text,
  Button
} from '@primer/react';
import { Dialog } from '@primer/react/experimental';
import { addDoc, collection, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { XIcon } from '@primer/octicons-react';

interface FeedbackModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess?: () => void;
}

const FEEDBACK_TYPES = [
  { label: 'Feedback', value: 'Feedback' },
  { label: 'Feature Request', value: 'Feature Request' },
  { label: 'Bug Report', value: 'Bug Report' }
] as const;

export function FeedbackModal({ isOpen, onDismiss, onSuccess }: FeedbackModalProps) {
  const [feedbackType, setFeedbackType] = useState<string>(FEEDBACK_TYPES[0].value);
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();

  const handleSubmit = async () => {
    if (!feedbackText.trim() || !user) return;
    
    setIsSubmitting(true);
    try {
      await addDoc(collection(db, 'feedback'), {
        feedback_type: feedbackType,
        feedback_text: feedbackText,
        user_ref: doc(db, 'users', user.uid),
        user_email: user.email,
        created_at: new Date(),
      });
      
      onDismiss();
      onSuccess?.();
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Dialog 
      title="Submit Feedback"
      onClose={onDismiss}
      width="xlarge"
      sx={{ 
        bg: 'canvas.default',
        maxWidth: '50vw !important',
        margin: '0 auto'
      }}
      renderHeader={() => (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 3,
          borderBottom: '1px solid',
          borderColor: 'border.default'
        }}>
          <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>Submit Feedback</Text>
          <Button 
            variant="invisible"
            onClick={onDismiss}
            sx={{ color: 'fg.muted', p: 1 }}
          >
            <XIcon />
          </Button>
        </Box>
      )}
      footerButtons={[
        {
          content: 'Cancel',
          onClick: onDismiss,
          variant: 'default'
        },
        {
          content: 'Submit Your Feedback',
          onClick: handleSubmit,
          variant: 'primary',
          disabled: !feedbackText.trim() || isSubmitting,
          sx: {
            bg: 'success.emphasis',
            color: 'white',
            '&:hover:not([disabled])': {
              bg: 'success.emphasis',
              color: 'white'
            }
          }
        }
      ]}
    >
      <Box sx={{ p: 3, bg: 'canvas.default' }}>
        <Box sx={{ mb: 3 }}>
          <Text as="label" sx={{ display: 'block', mb: 2 }}>
            Feedback Type
          </Text>
          <Select
            value={feedbackType}
            onChange={(e) => setFeedbackType(e.target.value)}
            sx={{ width: '100%' }}
          >
            {FEEDBACK_TYPES.map(type => (
              <Select.Option key={type.value} value={type.value}>
                {type.label}
              </Select.Option>
            ))}
          </Select>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Text as="label" sx={{ display: 'block', mb: 2 }}>
            Your Feedback
          </Text>
          <Textarea
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            rows={7}
            placeholder="Please provide your feedback here..."
            sx={{ width: '100%' }}
          />
        </Box>
      </Box>
    </Dialog>
  );
} 